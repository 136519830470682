export const Translations = {
  ERROR: {
    EMAIL_REQUIRED: "Your email address is required.",
    FIRSTNAME_REQUIRED: "Your first name is required.",
    INVALID_EMAIL: "Your email address is not valid.",
    LASTNAME_REQUIRED: "Your last name is required.",
    COMPANY_NAME_REQUIRED: "Your company name is required.",
    PASSWORD_REQUIRED: "Your password is required.",
    SOMETHING_WENT_WRONG: "Something went wrong.",
    CHECKBOX_SELECTION_REQUIRED:
      "You will need to check this checkbox before registering.",
    CHECKBOX_SELECTION_REQUIRED_HELPPAGE:
      "You will need to check this checkbox.",
    HELPTEXT_REQUIRED: "Please provide information on how we can help you.",
    CAPTCHA_REQUIRED: "Please verify that you are not a robot.",
    ALPHABET_ONLY:
      "Please enter only letters. Numbers, spaces and symbols are not allowed.",
    IF_USER_WANT_TO_ATTEND_EVENT:
      "If you would like to attend this event please",
    HTML_JS_CODE_NOT_ALLOWE: "HTML or JS code are not allowed.",
    THE_DETAILS_YOU_HAVE_ENTERED_NOT_CORRECT:
      "The details you have entered are not correct, please try again or",
    THE_DETAIL_YOU_HAVE_ENTERED_NOT_CORRECT_FOR_NO_PASSWORD:
      "The email address you entered is not yet registered. Please click the \"Register now\" button below to access the registration page, or",
      JOB_TITLE_REQUIRED: "Your job title is required "  
  },
  SUCCESS: {
    REGISTER_HEADING: "Your registration has been submitted.",
    moderated: {
      REGISTER_TEXT:
      "Your registration request will be reviewed and if successful, you will receive an email with the credentials to log into the site.",
    },
    guestlist: {
      REGISTER_TEXT:
      "You will receive an email within the next 5 minutes if you are on the guest list. If you are not on the preregistered guest list, you will not receive an email and you will need to request to be added by using the ",
    
    },
    no_password: {
      REGISTER_TEXT:
      "Thank you for your registration.  You will receive a confirmation email shortly and please also check your spam folder if it does not appear in your inbox.  On the day of the event, you would be able to log in and watch the event using the email address you used for registration.",
    },
    RESET_HEADING: "Your password change request is complete.",
    RESET_TEXT:
      "If you are a registered user, you will receive an email with the reset details.",
    GENERATE_PASSWORD_HEADING: "Your password generation request is complete.",
    GENERATE_PASSWORD_TEXT:
      "If you are a registered user, you will receive an email with the generated password.",
    HELP_HEADING: "Great news, your help request has been submitted.",
    HELP_TEXT: "The team will review your request and will get back to you.",
  },
  registerPage: {
    title: "Register",
    moderated: {
      description:
        "Welcome to IDX Event: Want to hear a good story? 85% of your investors do. To gain access to this event you must register using the form below. Once you have registered you will be emailed a password which you will need to log into the event.",
    },
    guestlist: {
      description:
        "To gain access to this event you must register below. Once you have registered you will be emailed your unique password and a link to the event log in.",
    },
  },
  loginPage: {
    title: "Log in",
    description: "Welcome to IDX Event: Want to hear a good story? 85% of your investors do. Please enter your email address and the password that was emailed to you after you registered for the event.",
    description2: "If you do not have a password, please register using the registration button below or contact us using the Help Page."
  },
  loginWithoutPasswordPage: {
    title: "Log in",
    description: "Welcome to IDX Event: Want to hear a good story? 85% of your investors do. Please enter your email address and the password that was emailed to you after you registered for the event.",
    description2: "If you do not have a password, please register using the registration button below or contact us using the Help Page.",
    resetWarning: "This event does not require a Password. Try to log in or contact us using the Help page."
  },
  ssoPage: {
    buttonText: "Log in with IDX account",
    description: "Please use your work email to log in to the event.",
    eventNotActive: "The event has not yet started. You will be able to log in once the event starts or",
  },
  ssoIssue: {
    title: "Access issue",
    description: "There was an issue with the single sign-on process. Please try again or contact us using the ",
    retrySso: "Kindly select your account...",
  },
  PageNotFoundPage: {
    title: "Page not found",
    description: "You've come to a page that doesn't currently exist. In case of any issues please contact us using the ",
  },
  helpPage: {
    faqSectionTitle: "FAQ",
    faqSectionQuestionsAnswers: [
      {
        question:
          "Can the presenter or the other attendees of a live webcast see you?",
        answer:
          "No, the presenter and other attendees of the webcast will not be able to see or hear you. However, you may have the chance to submit questions to the presenter during a live session and answer polls posed by the presenter.",
      },
      {
        question: "Can I attend the event on my phone?",
        answer:
          "Yes, you can attend the event on your phone. There is no need for any downloads or plugins when you attend the event, so you can easily access the content no matter whether you are travelling, at home or at the office.",
      },
      {
        question:
          "Why did the question I ask the presenter not appear in the Questions panel of the live presentation?",
        answer:
          "Questions you submit are first moderated by administrators before they appear on the Questions panel. It’s best to ask your questions as early as possible to give the moderators time to review and approve the question for display.",
      },
      {
        question: "Can I chat to other attendees during the live webcast?",
        answer:
          "Yes, if the Chat panel has been enabled for this event, you can collaborate to the other attendees watching the same live webcast",
      },
      {
        question:
          "Can I access a recording of a live webcast once the presentation finishes?",
        answer:
          "Yes, you can access the recording through the Resources section of the site. It takes an hour or two before the on-demand recorded version of the webcast will be available, as post-production processes first need to complete.",
      },
    ],
  },
  TEXT: {
    CLIICK_TO_RELOAD: "Click to Reload",
  },
}
